.dashboard-content {
    height: 90%;
    width: 90%;
}
.flag-SQ {
    border-radius: 100px;
    background-image: url('../assets/al.svg');
    border-radius: 100px;
    background-size: cover;
    height: 30px;
    width: 30px;
    /* opacity: 0.7; */
    background-position: center;
}
.flag-MK {
    border-radius: 100px;
    background-image: url('../assets/mk.svg');
    border-radius: 100px;
    background-size: cover;
    height: 30px;
    width: 30px;
    /* opacity: 0.7; */
    background-position: center;
}
.table-container {
    height: 100%;
    min-height: 250px;
    width: 100%;
    box-shadow: 0 0px 13px 7px #d7d7d7;
    border-radius: 10px;
}
.select-countries {
    cursor: pointer;
    color: #969696;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0px 13px 7px #d7d7d738;
    width: 250px;
    background: #fff;
}

.manage-first {
    width: 525px;
}
.ACTIVE {
    color: #72bf45;
}
.ENDED {
    color: #ed1b25;
}
.select-products {
    /* width: 450px; */
}

.filter-main-tables {
    position: absolute;
    right: 258px;
    top: -172px;
}
.filter-product-table {
    position: absolute;
    right: 0;
    top: -66px;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #72bf45 !important;
}

.manage-body {
    height: calc(100% - 200px);
    overflow: auto;
}
.right-side {
    width: calc(100% - 526px);
    min-width: 600px;
}
.left-side {
    overflow-y: auto;
}
.right-side {
    overflow-y: auto;
}
.flyer-table-header {
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #70bf4548;
    color: #969696;
    border-radius: 10px 10px 0 0;
    font-size: 17px;
    position: relative;
}
.add-category-icon {
    position: absolute;
    right: 10px;
    cursor: pointer;
}
.phone {
    padding: 6px;
    height: 38px;
    width: 100%;
    border: 1px solid #b4afaf;
    color: #969696;
    background: #f9fdf7;
    border-radius: 10px;
}
.phone > input {
    background: #f9fdf7;
}
