/* input-styles.css */
.input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: rgb(34, 34, 34);
}

.input:focus {
    outline: none;
    border-color: #72bf45;
}

/* button-styles.css */
.button {
    width: 100%;
    padding: 10px;
    background-color: #72bf45;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
}

.button:hover {
    background-color: #72bf45;
}
.product label {
    font-size: 14px;
    color: #969696;
}
.manage-first {
    border-right: 2px solid #f0f0f0;
}
.border-underline {
    border-bottom: 2px solid #f0f0f0;
}
.multi-select {
    width: 250px;
    /* border: 1px solid #8080803d; */
    background: white;
    color: #969696;
    /* display: flex; */
    /* border-radius: 12px; */
    font-size: 14px;
    font-weight: 600;
    border-radius: 5px;
    box-shadow: 0 0px 13px 7px #d7d7d738;
    width: 250px;
}
.multi-select button {
    text-transform: capitalize;
    text-align: left;
    width: 100%;
    color: #969696;
    font-size: 14px;
    font-weight: 600;
}
.multi-select button:hover {
    background: #fff;
}
.sub-description {
    font-size: 14px;
    color: #969696;
}
.custom-input {
    height: 38px;
    border-radius: 10px;
}
.custom-select {
    position: relative;
    display: inline-block;
}

.custom-select select {
    -webkit-appearance: none; /* Safari and Chrome */
    -moz-appearance: none; /* Firefox */
    appearance: none; /* Standard */
    cursor: pointer;
    color: #969696;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0 0px 13px 7px #d7d7d738;
    width: 250px;
    background-color: #fff !important;
    background: none;
    height: 38px;
    /* background: url('../assets/down.svg') no-repeat right center; */
    /* background-size: 10px; */
}

.custom-select::after {
    content: ''; /* No content needed */
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    background: url('../assets/down.svg') no-repeat;
    width: 15px;
    height: 15px;
}
.validation-error {
    border: 1px solid #ce0505 !important;
    border-radius: 10px;
}
.status-0 {
    color: #fce83a;
}
.status-1 {
    color: #2dccff;
}
.status-2 {
    color: #72bf45;
}

.brand-select {
    position: relative;
    display: inline-block;
}

.brand-select select {
    -webkit-appearance: none; /* Safari and Chrome */
    -moz-appearance: none; /* Firefox */
    appearance: none; /* Standard */
    cursor: pointer;
    color: #969696;
    padding: 8px;
    width: 100%;
    background-color: rgb(249, 253, 247) !important;
    background: none;
    height: 38px;
    border-radius: 10px;
    border: 1px solid #eeeeee;
    color: #969696;
}

.brand-select::after {
    content: ''; /* No content needed */
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    background: url('../assets/down.svg') no-repeat;
    width: 15px;
    height: 15px;
}
