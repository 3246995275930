@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'CustomFont';
    src: url('./fonts/MyriadProRegular.otf') format('opentype'),
        url('./fonts/MyriadProBoldCond.otf') format('opentype'),
        url('./fonts/MyriadProBold.otf') format('opentype'),
        url('./fonts/MyriadProBoldCondit.otf') format('opentype')
            url('./fonts/MyriadProBoldit.otf') format('opentype')
            url('./fonts/MyriadProCond.otf') format('opentype')
            url('./fonts/MyriadProBoldCondit.otf') format('opentype')
            url('./fonts/MyriadPro-Light.otf') format('opentype')
            url('./fonts/MyriadProSemiBold.otf') format('opentype')
            url('./fonts/MyriadProSemiBoldit.otf') format('opentype');
    /* Add additional font formats if necessary */
    /* Specify the correct path to the font files */
}
body {
    font-family: 'CustomFont', sans-serif;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
:focus-visible {
    outline: none;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}
.active-link {
    color: #ed1c24;
    font-weight: 600;
}
.image-container {
    height: 60px;
    margin-left: 10px;
    padding: 6px;
}
.css-17mclh2-MuiInputBase-root-MuiOutlinedInput-root,
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 10px !important;
}
.f24 {
    font-size: 24px;
}
.f14 {
    font-size: 14px;
}
.f20 {
    font-size: 20px;
}
.f17 {
    font-size: 17px;
}
.body-content label {
    font-size: 14px;
}
.p-button .p-button-label {
    transition-duration: 0.2s;
}
.p-button-label {
    flex: 1 1 auto;
}
.p-dialog .p-dialog-footer button {
    margin: 0 0.5rem 0 0;
    width: auto;
}
.p-button.p-button-text {
    background-color: transparent;
    color: #4b5563;
    border-color: transparent;
}
.p-button {
    margin: 0;
    display: inline-flex;
    cursor: pointer;
    user-select: none;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
}
.p-button {
    color: #ffffff;
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s,
        box-shadow 0.2s;
    border-radius: 6px;
}
.p-dialog .p-dialog-footer {
    border-top: 0 none;
    background: #ffffff;
    color: #4b5563;
    padding: 0 1.5rem 1.5rem 1.5rem;
    text-align: right;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}
.p-dialog-footer {
    flex-shrink: 0;
}
.p-component,
.p-component * {
    box-sizing: border-box;
}
.p-button.p-button-danger,
.p-button-group.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button,
.p-fileupload-choose.p-button-danger {
    color: #ffffff;
    background: #ef4444;
    border: 1px solid #ef4444;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.hide-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input,
.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
    color: #969696 !important;
}
.validation-error {
    border-color: red;
}
.loader {
    border: 2px solid #72bf45;
    border-top: 2px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
