/* navbar-styles.css */
.navbar {
  display: flex;
  flex-direction: column;
  width: 180px; /* Adjust width as needed */
  height: 100vh;
  background-color: #f0f0f0;
  color: #707070;
}

.top-section {
  padding: 20px;
  text-align: center;
}

.middle-section {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 20px;
  
}

.middle-section ul {
  list-style-type: none;
  padding: 0;
}

.middle-section li {
  margin-bottom: 10px;
}

.bottom-section {
  padding: 20px;
  text-align: center;
}
.logout-icon{
  display: flex;
  cursor: pointer;
}