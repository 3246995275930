/* login-page-styles.css */
.login-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #8d1b1b; 
    color: #fff; /* White text */
}

.logo-container {
    margin-bottom: 20px; /* Spacing between logo and form */
}

.logo img {
    width: 100px; /* Adjust the width of the logo */
    height: auto;
}
.form-container {
    display: flex;
    /* Styles for the login form container */
}
